import React from 'react';

const VehicleGallery = () => {
  const vehicleImages = [
    'https://seattlebluecabs.com/bluebackend/image1.jpg', // Replace with your actual image paths
    'https://seattlebluecabs.com/bluebackend/image2.jpg', // Replace with your actual image paths
    'https://seattlebluecabs.com/bluebackend/image3.jpg', // Replace with your actual image paths
    'https://seattlebluecabs.com/bluebackend/image4.jpg', // Replace with your actual image paths
    'https://seattlebluecabs.com/bluebackend/image5.jpg', // Replace with your actual image paths
    'https://seattlebluecabs.com/bluebackend/image6.jpg', // Replace with your actual image paths
    
    
  ];

  return (
    <div style={styles.galleryContainer}>
      <h2 style={styles.title}>Our Fleet</h2>
      <div style={styles.galleryGrid}>
        {vehicleImages.map((image, index) => (
          <div key={index} style={styles.imageWrapper}>
            <img src={image} alt={`Vehicle ${index + 1}`} style={styles.image} />
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  galleryContainer: {
    padding: '60px 20px',
    backgroundColor: '#fff',
    textAlign: 'center',
  },
  title: {
    fontSize: '36px',
    marginBottom: '30px',
  },
  galleryGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '20px',
  },
  imageWrapper: {
    overflow: 'hidden',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  image: {
    width: '100%',
    height: 'auto',
    transition: 'transform 0.3s ease',
  },
};

export default VehicleGallery;
