import React from 'react';
import VehicleGallery from './VehicleGallery'; // Import the VehicleGallery component

const HomePage = () => {
  return (
    <div style={styles.container}>
      {/* Hero Section */}
      <div style={styles.heroSection}>
        <h1 style={styles.heroTitle}>Reliable, On-Time, and Affordable Transportation</h1>
        <p style={styles.heroSubtitle}>Serving the Seattle Area with Professionalism and Care</p>
        <a href="tel:+12067474155" style={styles.heroButton}>
          Call Us Now: (206) 747-4155
        </a>
      </div>

      {/* Services Section */}
      <div style={styles.servicesSection}>
        <h2 style={styles.sectionTitle}>Our Services</h2>
        <div style={styles.servicesGrid}>
          <div style={styles.serviceItem}>
            <h3>Airport Transfers</h3>
            <p>Get to the airport on time, every time. We offer reliable airport transfer services.</p>
          </div>
          <div style={styles.serviceItem}>
            <h3>Local Transportation</h3>
            <p>Need a ride across town? We’ve got you covered with safe, local transportation.</p>
          </div>
          <div style={styles.serviceItem}>
            <h3>Corporate Services</h3>
            <p>Professional transportation solutions for business meetings and corporate events.</p>
          </div>
          <div style={styles.serviceItem}>
            <h3>Special Requests</h3>
            <p>We can accommodate unique transportation needs. Contact us for more details.</p>
          </div>
        </div>
      </div>

      {/* About Section */}
    {/*  <div style={styles.aboutSection}>
        <h2 style={styles.sectionTitle}>About Us</h2>
        <p>
          At Blue Cab & Blue For Hire, we pride ourselves on offering reliable, affordable, and convenient transportation services. Whether you’re heading to the airport, a business meeting, or just running errands around town, our dedicated team of professionals is here to serve you with punctuality and care.
        </p>
      </div>*/}

      {/* Vehicle Gallery Section */}
      <VehicleGallery /> {/* Add the VehicleGallery component here */}

      {/* Testimonials Section */}
      <div style={styles.testimonialsSection}>
        <h2 style={styles.sectionTitle}>What Our Customers Say</h2>
        <div style={styles.testimonialsGrid}>
          <div style={styles.testimonialItem}>
            <p>"Blue Cab & Blue For Hire is the best! They always arrive on time, and the drivers are super friendly. Highly recommend!"</p>
            <p>- Sarah K.</p>
          </div>
          <div style={styles.testimonialItem}>
            <p>"I use Blue Cab & Blue For Hire for all my airport trips. The flat rates and reliable service make traveling stress-free."</p>
            <p>- Mark J.</p>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div style={styles.contactSection}>
        <h2 style={styles.sectionTitle}>Contact Us</h2>
        <p>Ready to book your ride? Call us today at <strong>(206) 747-4155</strong> or use our online booking system.</p>
      </div>

      {/* Footer */}
      
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
  heroSection: {
    backgroundColor: '#0044cc',
    color: '#fff',
    padding: '100px 20px',
    textAlign: 'center',
  },
  heroTitle: {
    fontSize: '48px',
    margin: '0 0 20px',
  },
  heroSubtitle: {
    fontSize: '24px',
    margin: '0 0 40px',
  },
  heroButton: {
    padding: '10px 20px',
    fontSize: '18px',
    backgroundColor: '#ffcc00',
    color: '#0044cc',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  servicesSection: {
    padding: '60px 20px',
    backgroundColor: '#f4f4f4',
  },
  sectionTitle: {
    fontSize: '36px',
    textAlign: 'center',
    margin: '0 0 40px',
  },
  servicesGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  serviceItem: {
    flex: '1 1 45%',
    backgroundColor: '#fff',
    padding: '20px',
    margin: '10px',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  aboutSection: {
    padding: '60px 20px',
    backgroundColor: '#fff',
  },
  testimonialsSection: {
    padding: '60px 20px',
    backgroundColor: '#f4f4f4',
  },
  testimonialsGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  testimonialItem: {
    flex: '1 1 45%',
    backgroundColor: '#fff',
    padding: '20px',
    margin: '10px',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  contactSection: {
    padding: '60px 20px',
    backgroundColor: '#0044cc',
    color: '#fff',
    textAlign: 'center',
  },
  footer: {
    padding: '20px',
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
  },
};

export default HomePage;
