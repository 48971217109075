import React, { useState } from 'react';

const BookingForm = () => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [pickup, setPickup] = useState('');
  const [destination, setDestination] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [fleet, setFleet] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    alert('Booking Submitted!');
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleSubmit}>
        <h2>Book a trip</h2>

        {/* Date and Time */}
        <div style={styles.inputGroup}>
          <label>Date</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Time (24 hour)</label>
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            style={styles.input}
          />
        </div>

        {/* Pickup and Destination */}
        <div style={styles.inputGroup}>
          <label>Pickup</label>
          <input
            type="text"
            placeholder="Pickup location"
            value={pickup}
            onChange={(e) => setPickup(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Destination</label>
          <input
            type="text"
            placeholder="Destination location"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            style={styles.input}
          />
        </div>

        {/* Additional Stop */}
        <div style={styles.additionalStop}>
          <a href="/">Add additional stop</a>
        </div>

        {/* Payment Method */}
        <div style={styles.inputGroup}>
          <label>Payment Method</label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            style={styles.input}
          >
            <option value="">Select payment method</option>
            <option value="credit">Credit Card</option>
            <option value="cash">Cash</option>
          </select>
        </div>

        {/* Fleet */}
        <div style={styles.inputGroup}>
          <label>Company</label>
          <select
            value={fleet}
            onChange={(e) => setFleet(e.target.value)}
            style={styles.input}
          >
            <option value="">Select a fleet</option>
            <option value="blue-cab">Blue Cab</option>
            <option value="blue-for-hire">Blue For Hire</option>
          </select>
        </div>

        {/* Submit Button */}
        <button type="submit" style={styles.submitButton}>
          Submit
        </button>
      </form>

      {/* Map (Placeholder) */}
      <div style={styles.map}>
        {/* Placeholder for Map */}
        <h3>Map Area</h3>
        <p>This is where the map will be displayed.</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    maxWidth: '1200px',
    margin: 'auto',
  },
  form: {
    width: '40%',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  additionalStop: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  submitButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#0044cc',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  map: {
    width: '55%',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
};

export default BookingForm;
