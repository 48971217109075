import React from 'react';

const AboutUs = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <h1>About Us</h1>

      <section>
        <h2>Welcome to Blue Cab & Blue For Hire!</h2>
        <p>
          At Blue Cab & Blue For Hire, we pride ourselves on offering reliable, affordable, and convenient transportation services to the communities we serve. Our commitment to customer satisfaction and safety is at the core of everything we do, ensuring that every ride with us is smooth and hassle-free.
        </p>
      </section>

      <section>
        <h2>Who We Are</h2>
        <p>
          Blue Cab & Blue For Hire is more than just a transportation company; we are a team of dedicated professionals with a shared passion for service. Our fleet is composed of well-maintained, eco-friendly vehicles, ready to take you to your destination with ease and comfort. Whether you’re heading to the airport, a business meeting, or just running errands around town, we’ve got you covered.
        </p>
      </section>

      <section>
        <h2>Our Mission</h2>
        <p>
          Our mission is to provide a transportation experience that is both efficient and pleasant, with a focus on flat-rate pricing that ensures transparency and affordability for all our customers. We believe in putting the customer first, which means you can always expect prompt, courteous service every time you choose Blue Cab & Blue For Hire.
        </p>
      </section>

      <section>
        <h2>Why Choose Us?</h2>
        <ul>
          <li><strong>Flat-Rate Pricing:</strong> No surprises, just clear and straightforward rates.</li>
          <li><strong>Reliable Service:</strong> We are committed to getting you where you need to be, on time, every time.</li>
          <li><strong>Eco-Friendly Vehicles:</strong> Our fleet is composed of hybrid vehicles, contributing to a greener environment.</li>
          <li><strong>Professional Drivers:</strong> Our drivers are experienced, friendly, and dedicated to providing top-notch service.</li>
          <li><strong>24/7 Availability:</strong> Whether it’s early morning or late at night, Blue Cab & Blue For Hire is always at your service.</li>
        </ul>
      </section>

      <section>
        <h2>Join Our Community</h2>
        <p>
          We are more than just a transportation service; we are a part of your community. We strive to make a positive impact by offering services that are not only dependable but also contribute to the well-being of our environment and society.
        </p>
        <p>
          Thank you for choosing Blue Cab & Blue For Hire. We look forward to serving you!
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
