import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import BookingForm from './components/BookingForm';
import MapComponent from './components/MapComponent';


import ContactUs from './components/ContactUs';

import './App.css';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
         <Route path="/booking-form" element={<BookingForm />} />
         <Route path="/map-Component" element={<MapComponent />} />
        
        <Route path="/contact-us" element={<ContactUs />} />
        {/* Add other routes here */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
